
.ql-picker.ql-font .ql-picker-label[data-value="Pretendard"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="Pretendard"]::before
{
    font-family: "Pretendard", cursive;
    content: "Pretendard" !important;
}






/* Set content font-families */
.ql-font-Pretendard {
  font-family: "Pretendard";
}



.ql-picker.ql-size .ql-picker-item[data-value="8px"]::before {
  content: "8";
  font-size: 8px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="11px"]::before {
  content: "11";
  font-size: 11px !important;
}


.ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12";
  font-size: 12px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14";
  font-size: 14px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16";
  font-size: 16px !important;
}
.ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20";
  font-size: 20px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24";
  font-size: 24px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28";
  font-size: 28px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="30px"]::before {
  content: "30";
  font-size: 30px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "36";
  font-size: 36px !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="44px"]::before {
  content: "44";
  font-size: 44px !important;
}



/* 0.85, 0.9, 0.95, 1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2.0, */

.ql-picker.ql-lineHeights .ql-picker-item[data-value="0.85"]::before {
  content: "0.85";
}

.ql-picker.ql-lineHeights .ql-picker-item[data-value="0.9"]::before {
  content: "0.9";
}

.ql-picker.ql-lineHeights .ql-picker-item[data-value="0.95"]::before {
  content: "0.95";
}

.ql-picker.ql-lineHeights .ql-picker-item[data-value="1.0"]::before {
  content: "1.0";
}

.ql-picker.ql-lineHeights .ql-picker-item[data-value="1.1"]::before {
  content: "1.1";
}

.ql-picker.ql-lineHeights .ql-picker-item[data-value="1.2"]::before {
  content: "1.2";
}

.ql-picker.ql-lineHeights .ql-picker-item[data-value="1.3"]::before {
  content: "1.3";
}

.ql-picker.ql-lineHeights .ql-picker-item[data-value="1.4"]::before {
  content: "1.4";
}

.ql-picker.ql-lineHeights .ql-picker-item[data-value="1.5"]::before {
  content: "1.5";
}

.ql-picker.ql-lineHeights .ql-picker-item[data-value="1.6"]::before {
  content: "1.6";
}

.ql-picker.ql-lineHeights .ql-picker-item[data-value="1.7"]::before {
  content: "1.7";
}

.ql-picker.ql-lineHeights .ql-picker-item[data-value="1.8"]::before {
  content: "1.8";
}

.ql-picker.ql-lineHeights .ql-picker-item[data-value="1.9"]::before {
  content: "1.9";
}

.ql-picker.ql-lineHeights .ql-picker-item[data-value="2.0"]::before {
  content: "2.0";
}

.ql-video {
  width:100%;
  aspect-ratio: 16/9;
}

.ql-editor {
  background-color: white;
}

.quill {
  height: 100%;
}

.editor .ql-container {
  height: 200px;
}

.ql-tooltip {
  left: 0 !important;
}