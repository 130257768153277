/* These styles make the body full-height */
html,
body {
  height: 100%;
}
/* These styles disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
  box-sizing: border-box;
}
/* These styles make the root element full-height */
#root {
  display: flex;
  height: 100%;
}

input {
  outline: none;
  border: none;
}

input:focus {
  box-shadow: none;
  outline: none;
}

textarea {
  outline: none;
  border: none;
}

textarea:focus {
  box-shadow: none;
}

input[type="file"] {
  display: none;
}

@font-face {
  font-family: "Pretendard-Light";
  src: url("./assets/fonts/Pretendard-Light.otf");
  font-weight: lighter;
}

@font-face {
  font-family: "Pretendard-Bold";
  src: url("./assets/fonts/Pretendard-Bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("./assets/fonts/Pretendard-Regular.otf");
  font-weight: normal;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(222, 220, 220);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.htmlwrapper img{
  width: 100%;
  max-width: fit-content;
}